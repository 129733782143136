import type { BeforeAfterItemInterface } from "astro-types/components-interfaces";

import { ImgComparisonSlider } from "@img-comparison-slider/react";

import styles from "./BeforeAfterItem.module.css";

interface Props extends BeforeAfterItemInterface {}

function BeforeAfterItem({
  altText,
  beforeImage,
  afterImage,
  label,
  isDark,
}: Props) {
  return (
    <article className={styles.article} data-theme={isDark ? "dark" : "light"}>
      <ImgComparisonSlider className={styles.slider}>
        <img
          alt={altText}
          slot="first"
          className={`${styles.image} ${styles.first}`}
          src={beforeImage.src}
        />
        <img
          alt={altText}
          slot="second"
          className={`${styles.image} ${styles.second}`}
          src={afterImage.src}
        />
        {/* <img slot="handle" src={handle} alt="handle" /> */}
      </ImgComparisonSlider>
      <p className={styles.cardTitle}>{label}</p>
    </article>
  );
}

export default BeforeAfterItem;
